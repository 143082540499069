import type { MediaItemInterface } from '@hypercodestudio/basler-components/dist/components/elements/media/Media.vue';
import type { IVideoFields } from '~/lib/ContentfulService';
import { videoUrlCalculator } from '~/utils/videoUrlCalculator';
import { videoFormatCalculator } from '~/utils/videoFormatCalculator';
import { videoType } from '~/utils/videoType';
import type { VideoFormat } from '~/types/VideoFormat';

export function generateVideoObject(
  videoFields: IVideoFields
): MediaItemInterface {
  const availableVideos: { format: VideoFormat; url: string }[] = [];

  availableVideos.push({
    format: videoFormatCalculator(
      videoFields.localVideoAsset?.fields?.file?.url
    ),
    url: videoUrlCalculator(videoFields) ?? ''
  });

  availableVideos.push({
    format: videoFormatCalculator(
      videoFields.localVideoAssetFallback?.fields?.file?.url
    ),
    url: videoUrlCalculator(videoFields, true) ?? ''
  });

  const type = videoType(videoFields?.externalVideoUrl);
  const webmUrl = availableVideos.find((v) => v.format === 'webm')?.url;
  const mp4Url = availableVideos.find((v) => v.format === 'mp4')?.url;
  const noneUrl = availableVideos.find((v) => v.format === 'none')?.url;

  return {
    type,
    content: {
      posterImage:
        videoFields.posterImage?.fields?.file?.url !== undefined
          ? videoFields.posterImage?.fields?.file?.url +
              '?fm=webp&f=center&w=1400&q=80&fit=pad' ?? ''
          : videoFields.posterImage?.fields?.file?.url,
      webm: webmUrl ?? '',
      mp4: mp4Url ?? '',
      url: noneUrl ?? webmUrl ?? mp4Url ?? '',
      autoplay: videoFields.autoplay ?? false,
      displayPlayerInterface: videoFields.displayPlayerInterface ?? true,
      width: type === 'iframe' ? -1 : undefined,
      loop: videoFields.loopVideo ?? true,
      height:
        type === 'iframe'
          ? getTencentHeight(videoFields?.externalVideoUrl ?? '')
          : undefined
    },
    caption: videoFields.caption,
    styleType: videoFields.style ?? 'default'
  };
}

const getTencentHeight = (url: string) => {
  let height = '';
  let heightIndex = url.indexOf('height=');
  if (heightIndex) {
    heightIndex += 'height='.length;
    const videoUrlFromHeightBegining = url.substring(heightIndex);
    for (const letter of videoUrlFromHeightBegining) {
      if (isNaN(Number(letter))) {
        break;
      }
      height += letter;
    }
  }
  return isNaN(Number(height)) ? '-1' : height;
};
